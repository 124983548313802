import { Menu } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { isEqual } from 'lodash';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import AC from '../../components/AccessControl';
const {
  SubMenu,
  Item
} = Menu;
const generateMenu = menuArray => menuArray.map(menu => {
  if (menu.hidden) {
    return null;
  }
  if (menu.children) {
    const menuChildren = generateMenu(menu.children);
    if (menuChildren.length === 0) {
      return null;
    }
    return <SubMenu key={menu.title} title={<span className="submenu-title-wrapper">
            {menu.title} {menu.icon ? <CaretDownOutlined /> : null}
          </span>}>
        {menuChildren}
      </SubMenu>;
  }
  const item = AC.Link({
    href: menu.link,
    label: menu.title
  });
  return item && <Item disabled={menu.disabled} key={menu.key}>
      {item}
    </Item>;
}).filter(e => e);
const selectedKeys = (menuData, router) => menuData.length > 0 ? [...selectedKeys(menuData.flatMap(e => e.children || []), router), ...menuData.filter(e => isEqual(e.link, router)).map(e => e.key)] : [];
const DashboardTabs = ({
  menuData
}) => {
  const router = useRouter();
  const {
    pathname,
    query
  } = router;
  return <Menu selectedKeys={selectedKeys(menuData, {
    pathname,
    query
  })} mode="horizontal" data-sentry-element="Menu" data-sentry-component="DashboardTabs" data-sentry-source-file="DashboardTabs.jsx">
      {generateMenu(menuData)}
    </Menu>;
};
export default DashboardTabs;